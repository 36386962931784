import React, { useCallback } from "react"
import styled from "styled-components"
import { filterByLang } from "@components/utils"
import Main from "./main"
import UnderHero from "@components/common/underHero"
import Header from "@components/common/header"
import Footer from "@components/common/footer"
import { EntryTemplateContext } from "@/gatsby-node/createEntries"
import { UseToggleLanguage } from "@components/hooks"
// ______________________________________________________
//
type Props = EntryTemplateContext &
  UseToggleLanguage & {
    className?: string
    title: string
  }
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  const toggleLanguage = useCallback(() => {
    if (props.lang === "ja") {
      props.toggleToEN()
    } else if (props.lang === "en-US") {
      props.toggleToJA()
    }
  }, [props.lang])

  return (
    <div className={props.className}>
      <Header toggleLanguage={toggleLanguage} lang={props.lang} />
      <UnderHero />
      <Main
        title={props.title}
        body={
          props.edges.filter(filterByLang(props.lang))[0]?.node?.body
            ?.childMarkdownRemark?.html ?? ""
        }
      />
      <Footer
        lists={
          props.lang === "ja"
            ? (props.site?.siteMetadata?.footerLinkListsJA as any)
            : (props.site?.siteMetadata?.footerLinkListsEN as any)
        }
      />
    </div>
  )
}

// ______________________________________________________
//
const StyledComponent = styled(Component)`
  background-color: ${({ theme }) => theme.colors.ivory};
`

// ______________________________________________________
//
export default StyledComponent
