import React from "react"
import { filterByLang } from "@components/utils"
import Layout from "@components/layouts"
import Meta from "@components/layouts/meta"
import Template from "@components/templates/2020/entries"
import { useToggleLanguage } from "@components/hooks"
import { EntryTemplateContext } from "@/gatsby-node/createEntries"
// ______________________________________________________
//
type Props = {
  pageContext: EntryTemplateContext
}
// ______________________________________________________
//
const Component: React.FC<Props> = ({ pageContext }) => {
  const { lang, toggleToEN, toggleToJA } = useToggleLanguage()
  if (lang === null) return null
  const node = pageContext.edges.filter(filterByLang(lang))[0]?.node
  const title = node.title || ""
  const pageTitle = `${title} | ${pageContext.site?.siteMetadata?.title}`
  return (
    <Layout env={pageContext.site?.siteMetadata?.env ?? ""}>
      <Meta title={pageTitle} />
      <Template
        {...pageContext}
        title={title}
        lang={lang}
        toggleToEN={toggleToEN}
        toggleToJA={toggleToJA}
      />
    </Layout>
  )
}
// ______________________________________________________
//
export default Component
