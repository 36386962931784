import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import ImgHero from "@/src/images/2020/img_logo_vertical.svg"

// ______________________________________________________
//
type Props = {
  className?: string
}

// ______________________________________________________
//

const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <div className="Inner">
      <h2 className="Logo">
        <ImgHero />
      </h2>
    </div>
    <div className="Gradient" />
  </div>
)

// ______________________________________________________
//
const spStyle = `
  .Inner {
    height: 200px;
    padding-top: 48px;
  }
  .Logo {
    max-width: 180px;
  }
`
const StyledComponent = styled(Component)`
  background: linear-gradient(180deg, #1c1a19 0%, #282c34 100%);
  .Inner {
    height: 320px;
    padding-top: 104px;
    box-sizing: border-box;
  }
  .Logo {
    max-width: 254px;
    margin: 0 auto;
    svg {
      width: 100%;
      height: auto;
    }
  }
  .Gradient {
    height: 16px;
    background: linear-gradient(
      180deg,
      #282c34 0%,
      #d33736 46.35%,
      #dd6041 60.94%,
      #ffe564 100%
    );
  }
  ${media.lessThan("medium")`${spStyle}`}
`

// ______________________________________________________
//
export default StyledComponent
