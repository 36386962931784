import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Body from "./body"
// ______________________________________________________
//
type Props = {
  className?: string
  title: string
  body: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Body body={props.body} />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
  max-width: ${1024 + 80}px;
  margin: 0 auto;
  padding: 104px 40px 112px;
  box-sizing: border-box;
`
const spStyle = `
  padding: 32px 20px 104px;
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
