import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
// ______________________________________________________
//
type Props = {
  className?: string
  body: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div
    className={props.className}
    dangerouslySetInnerHTML={{ __html: props.body }}
  />
)
// ______________________________________________________
//
const pcStyle = `
`
const spStyle = `
`
const StyledComponent = styled(Component)`
  color: ${({ theme }) => theme.colors.black};
  a {
    color: ${({ theme }) => theme.colors.black};
  }
  h1 {
    font-size: 2.4rem;
  }
  h2 {
    font-size: 2.2rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.8rem;
  }
  h5 {
    font-size: 1.6rem;
  }
  h5 {
    font-size: 1.4rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 40px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  ul {
    margin: 20px 0;
    li {
      &::before {
        content: "・";
      }
      padding-left: 1em;
      text-indent: -1em;
      line-height: 2;
    }
  }
  p {
    line-height: 2;
    margin: 20px 0;
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
